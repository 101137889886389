.dialog-wrapper {
  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    .icon {
      width: 5rem;
      height: 5rem;
      padding: 1rem;
      border-radius: 100%;
      border: 1px solid;
    }
  }

  .text {
    font-weight: 600;
    font-size: $h6;
    color: $gray;
    text-align: center;
  }

  .buttons-wrapper {
    .cancel-button {
      font-weight: 700;
      border: 1px solid rgba(128, 128, 128, 0.45);
    }

    .submit-button {
      font-weight: 700;
    }
  }

  &.danger {
    .icon-wrapper {
      .icon {
        border-color: $danger;
        color: $danger;
      }
    }

    .buttons-wrapper {
      .submit-button {
        color: $white;
        background-color: $danger;
      }
    }
  }

  .dialog-line {
    min-width: 95vw;
  }
}
