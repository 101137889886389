.menu-item-container {
  .menu-item-button {
    min-height: 2rem;
    color: $gray;
    font-size: $h7 !important;
    border-radius: 10px;

    .menu-icon {
      min-width: 0;
      margin-right: auto;
      justify-content: center;
    }

    .text {
      span {
        white-space: nowrap;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $h7 !important;
      }
    }

    .submenu-icon {
      display: none;
      margin-left: auto;
      margin-right: 0.75rem;
      min-width: 0;
      transition: all 0.2s ease-in-out;

      &.rotate {
        transform: rotate(-90deg);
      }
    }

    &.active {
      background: $primary;
      color: $white;

      .menu-icon {
        color: $white;
      }

      .submenu-icon {
        color: $white;
      }
    }

    &.open {
      .menu-icon {
        margin-right: 0.5rem;
        margin-left: 0.75rem;
      }

      .submenu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .submenu-list-container {
    background: $primary-background;
    border-radius: 10px;

    .submenu-item {
      color: $gray;
      border-radius: 10px;
      padding-right: 1.5rem;

      .text {
        span {
          font-size: $h7 !important;
        }
      }
      &.active {
        border: 1px solid $primary;
        color: $primary;
      }
    }
  }

  .responsive-submenu-list-container {
    .submenu-item {
      color: $gray;
      font-size: $h6;
      width: 100%;
    }
  }
}
