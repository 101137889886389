$primary: #3f71fc;
$bgLayout: #f1f7ff;
$secondary: #ed001e;
$info: #163ba2;
$navy: #3c8dbc;
$success: #3cbc6f;
$danger: #ff6a6a;
$warning: #ffb400;

$white: #fff;
$gray: #555555;
$black: #333333;

$primary-background: rgba(192, 217, 255, 0.22);
