body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: $fa-font-name;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

a {
  text-decoration: none !important;
}

.px-1 {
  padding: 0 0.25rem;
}

.px-2 {
  padding: 0 0.5rem;
}

.mx-1 {
  margin: 0 0.25rem;
}

.mx-2 {
  margin: 0 0.5rem;
}

.react-checkbox-tree label {
  display: flex;
  padding-top: 0.5rem;
  border-radius: 4px;
}

.excel-button-wrapper {
  position: absolute !important;
  left: 0;
  top: 20px;

  @media (max-width: 600px) {
    position: relative !important;
  }
}

.recharts-default-tooltip {
  overflow-y: auto;
  font-size: $h8 !important;
}

.MuiDateTimePickerToolbar-root {
  direction: ltr;
}

.date-label {
  direction: ltr;
  background-color: $navy;
  display: inline-flex;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
  color: $white;
  border-radius: 4px;
}
