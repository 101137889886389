.single-line-diagram-wrapper {
  .zoom-wrapper {
    width: 100%;
    min-height: 70vh;
    position: relative;
    margin-top: 1rem;
    border: 1px dashed $gray;
    border-radius: 20px;

    .zoom-space {
      width: 100%;
      height: 100%;

      > div {
        transform: translate(-1px) scale(0.4);
      }
    }
  }

  .diagrams {
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: scale(0.45) translate(0, -90vh);
    cursor: move;

    .vertical-line {
      width: 2px;
      background-color: $black;
      display: block;
    }

    .horizontal-line {
      height: 2px;
      background-color: black;
      display: block;
    }

    .arrow-down {
      width: 20px;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .line {
        height: 70px;
        margin: auto;
      }

      .icon {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 16px solid $black;
        display: block;
        margin: auto;
      }
    }

    .electric-meter {
      width: 180px;
      height: 60px;
      border: 3px solid $black;
      border-radius: 10px;
      display: block;
      margin: auto;
      padding: 5px;
      line-height: 50px;
      text-align: center;
      background-color: $warning;
      font-weight: 600;
    }

    .internal-trans {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin: auto;
      width: 400px;
      height: 70px;

      > div {
        width: 50%;
        height: 70px;
        display: flex;
        align-items: center;

        .line {
          width: 50px;
        }

        .icon {
          width: 50px;
          height: 30px;
          display: block;
          position: relative;

          .circle-first,
          .circle-second {
            width: 30px;
            height: 30px;
            display: block;
            border: 2px solid black;
            border-radius: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
          }
          .circle-first {
            left: 0;
          }
          .circle-second {
            right: 0;
          }
        }

        .detail {
          margin: 5px;
          text-align: center;
          width: 100px;
          font-size: 12px;
          font-weight: 500;
        }
      }

      &-right {
        border-left: 2px solid $black;
        direction: ltr;
      }
      &-left {
        border-right: 2px solid $black;
      }
    }

    .main-trans {
      width: 400px;
      height: 110px;
      display: block;
      margin: auto;
      position: relative;

      .title {
        width: 80px;
        direction: ltr;
        position: absolute;
        left: 250px;
        top: 50px;
        font-weight: 600;
        font-size: 25px;
      }

      .detail {
        width: 80px;
        direction: ltr;
        position: absolute;
        left: 50px;
        top: 50px;
        font-weight: 600;
      }

      .icon {
        width: 70px;
        height: 110px;
        display: block;
        margin: auto;
        position: relative;

        > span {
          width: 65px;
          height: 65px;
          display: block;
          border: 3px solid black;
          border-radius: 100%;
          position: absolute;
          right: 0;
          left: 0;
          margin: auto;
        }

        .circle-first {
          top: 0;
        }

        .circle-second {
          bottom: 0;
        }
      }
    }

    .serial-number {
      .before-line {
        height: 40px;
        margin: auto;
      }

      .counter-serial {
        display: block;
        margin: auto;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 13px;
        font-weight: 800;
        border: 2px solid black;
        background-color: rgba(172, 230, 230, 0.6);
        cursor: pointer;
      }

      .after-line {
        height: 30px;
        margin: auto;
      }
    }

    .bus-bar {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      margin: auto;
      vertical-align: top;

      .line {
        width: 100%;
        height: 6px;
      }

      .riser {
        width: 50px;
        height: 20px;
        border: 4px solid black;
        border-top: none;
        margin-left: -10px;
        margin-right: -10px;
      }
    }

    .feeders-section {
      //   margin-top: -20px;
      //   margin-right: 35px;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      height: 600px;

      .feeder {
        width: 100px;
        box-sizing: border-box;
        margin-inline: 20px;

        .line-before {
          margin: auto;
          height: 50px;
        }

        .counter-serial {
          width: 90px;
          height: 40px;
          display: block;
          line-height: 40px;
          text-align: center;
          font-size: 13px;
          font-weight: 800;
          background-color: #f3e6ab;
          border: 2px solid $black;
          margin: auto;
          cursor: pointer;
        }

        .arrow {
          position: relative;

          .line {
            width: 3px;
            height: 150px;
            margin: auto;
          }

          .icon {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 12px solid $black;
            display: block;
            margin: auto;
          }

          .title {
            position: absolute;
            top: 20px;
            left: 75px;
            font-weight: 700;
          }

          .icon-up {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 12px solid black;
            display: block;
            margin: auto;
          }

          &.up {
            .line {
              height: 80px;
            }
          }
        }
      }
    }

    .dg-section {
      &.bus-bar-injection {
        position: relative;
        top: -60px;
        left: 30px;
        direction: rtl;

        .dg-icon {
          margin-right: -35px;
        }

        .dg-detail {
          margin-right: -75px;
        }

        .dg-title {
          margin-right: -75px;
        }
      }

      &.feeder-injection {
        margin: auto;
        .dg-icon {
          margin: auto;
        }
        .dg-detail {
          margin-right: -25px;
        }

        .dg-title {
          margin-right: -25px;
        }
      }

      .line-top {
        width: 80px;
        height: 3px;
      }

      .line-right {
        height: 120px;
        width: 3px;
      }

      .dg-counter-serial {
        display: block;
        margin: auto;
        line-height: 40px;
        text-align: center;
        font-size: 13px;
        font-weight: 800;
        border: 2px solid $black;
        background-color: rgba(172, 230, 230, 0.6);
        width: 100px;
        height: 40px;
        line-height: 40px;
        direction: ltr;
        margin-right: -50px;
        font-size: 18px;
        cursor: pointer;
      }

      .arrow-up {
        margin-right: -8px;
        width: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .icon {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 12px solid black;
          margin: auto;
        }

        .line {
          height: 50px;
          width: 3px;
          margin: auto;
        }
      }

      .dg-icon {
        width: 70px;
        height: 70px;
        line-height: 70px;
        text-align: center;
        background-color: #f7c7d3;
        border: 2px solid black;
        border-radius: 100%;
      }

      .dg-detail {
        direction: rtl;
        margin-top: 12px;
        font-weight: 600;
        text-align: center;
        width: 150px;
      }

      .dg-title {
        direction: rtl;
        margin-top: 6px;
        font-weight: 800;
        text-align: center;
        width: 150px;
      }
    }

    .exchange-section {
      .mof-container {
        position: relative;
        .mof-detail {
          position: absolute;
          top: 15px;
          left: 90px;
        }
        .mof-title {
          width: 50px;
          height: 50px;
          border: 2px solid black;
          border-radius: 100%;
          line-height: 50px;
          text-align: center;
          margin: auto;
          background-color: #d2e2e6;
        }
      }

      .mof-arrow-down {
        .line {
          width: 3px;
          height: 20px;
          margin: auto;
        }
        .icon {
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 12px solid black;
          display: block;
          margin: auto;
        }
      }

      .exchange-counter-serial {
        width: 105px;
        height: 40px;
        border: 2px solid black;
        line-height: 40px;
        text-align: center;
        background-color: #d2e2e6;
        margin: auto;
        cursor: pointer;
      }

      .exchange-title {
        position: relative;
        top: 10px;
        right: 15px;
      }
    }
  }
}
