.p-1 {
    padding: 0.25rem;
}

.p-2 {
    padding: 0.5rem;
}

.p-3 {
    padding: 7.25rem;
}

.px-1 {
    padding: 0 0.25rem;
}

.px-2 {
    padding: 0 0.5rem;
}

.px-3 {
    padding: 0 0.75rem;
}

.pr-1 {
    padding-right: 0.25rem;
}

.pr-2 {
    padding-right: 0.5rem;
}

.pr-3 {
    padding-right: 7.25rem;
}

.pl-1 {
    padding-left: 0.25rem;
}

.pl-2 {
    padding-left: 0.5rem;
}

.pl-3 {
    padding-left: 0.75rem;
}

// Margin 
.mx-1 {
    margin: 0 0.25rem;
}

.mx-2 {
    margin: 0 0.5rem;
}

.mx-3 {
    margin: 0 0.75rem;
}

.mr-1 {
    margin-right: 0.25rem;
}

.mr-2 {
    margin-right: 0.5rem;
}

.mr-3 {
    margin-right: 0.75rem;
}

.ml-1 {
    margin-left: 0.25rem;
}

.ml-2 {
    margin-left: 0.5rem;
}

.ml-3 {
    margin-left: 0.75rem !important;
}