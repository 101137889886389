  .custom-table {
    .row {
      overflow: hidden;
      background: $white;
      border: 1px solid rgba(218, 230, 249, 0.46);

      &:nth-child(odd) {
        background: rgba(218, 230, 249, 0.46);
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &.header {
        border-radius: 10px 10px 0 0;
        padding: 0.625rem;
        font-weight: 500;
        font-size: $h6;
        line-height: 18px;
        text-align: center;
      }

      .cell {
        padding: 1rem 0.625rem;
        text-align: right;
        font-weight: 400;
        font-size: $h7;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-left: 1px solid rgba(218, 230, 249, 0.46);

        &.title {
          color: #8190a8;
        }

        &.value {
          color: #555555;
        }
      }
    }
  }