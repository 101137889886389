.selector-container {
    border: 1px solid #DAE6F9;
    border-radius: 5px;
    margin: 0.5rem;

    .divider {
        border-color: #DAE6F9;
    }

    .selector-list {
        padding: 0;
        height: 8rem;
        max-height: 8rem;
        overflow-y: auto;
    }

    .selector-item {
        font-size: 10px;
        height: 2.5rem;
        border-bottom: 1px solid #DAE6F9;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
    }
}