.home-container {
  .banner-image {
    width: 100%;
  }

  .stat {
    height: 4rem;
    background-color: $primary-background;
    display: flex;
    align-items: center;
    padding: 0.5rem;

    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
    border-radius: 20px;

    .stat-icon-container {
      width: calc(20% - 0.25rem);
      padding: 0.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: scale 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .stat-detail-container {
      width: calc(80% - 0.5rem);
      color: $gray;
      padding: 0 0.25rem;

      .stat-title {
        font-weight: 500;
        font-size: $h6;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }

      .stat-description {
        font-weight: 400;
        font-size: $h8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 16px 0px rgba(0, 0, 0, 0.14),
        0px 8px 24px 0px rgba(0, 0, 0, 0.12);

      .stat-icon-container {
        scale: 1.2;
      }
      .stat-detail-container {
        .stat-title {
          font-weight: bolder;
        }
      }
    }
  }
}
