.app-bar-container {
    .user-menu-button-container {
        color: $gray;

        .avatar {
            background-color: $white;
            width: 2.25rem;
            height: 2.25rem;
            border-radius: 100%;
            margin-right: .725rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .mobile-icon {
        display: none;
    }

    @media (max-width: 900px) {
        .desktop-icon {
            display: none;
        }

        .mobile-icon {
            display: flex;
        }
    }
}