.app-layout-container {
  .app-content-container {
    position: relative;
    margin: 4rem 15rem 0 0;
    background-color: $bgLayout;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
    overflow-y: auto;
    padding: 1rem 1.5rem;
    width: calc(100% - 15rem);

    &.full-width {
      width: calc(100% - 3.5rem);
      margin: 4rem 3.5rem 0 0;
    }
  }

  .module-sidebar-container {
    background-color: $white;
    overflow: auto;
    height: calc(100vh - 4rem);
    position: fixed;
    right: 4rem;
    top: 4rem;
    bottom: 0;
    max-width: 16rem;
    width: 12rem;
    transition: all 0.1s ease-out;

    &.hide {
      width: 0;
    }
  }

  @media (max-width: 600px) {
  }

  @media (max-width: 900px) {
    .app-content-container {
      width: 100%;
      margin: 4rem 0 0 0;
      padding: 1.25rem;

      &.full-width {
        width: 100%;
        margin: 4rem 0 0 0;
        padding: 1.25rem;
      }
    }

    .side-bar-container {
      display: none !important;
    }

    .module-sidebar-container {
      display: none;
    }
  }
}


.ck-content
{
  height: 200px !important;
}