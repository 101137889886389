.stat-container {
  padding-bottom: 0 !important;

  .header-container {
    text-align: center;

    .value {
      font-weight: 700;
      font-size: $h3;
      color: $black;
    }

    .unit {
      padding-left: 0.25rem;
      color: $gray;
    }
  }

  .label-container {
    text-align: center;
    font-weight: 500;
    font-size: $h5;
    color: $gray;
    margin: 0.5rem 0 1.5rem;
  }

  .info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #8190A8;
    font-weight: 400;
    margin-bottom: 10px;

    .info-value {
      font-size: $h7;
      direction: ltr;
    }

    .info-title {
      font-size: $h8;
    }

    .info-unit {
      font-size: $h9;
    }
  }

  .date-container {
    background: $primary-background;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;

    .label {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: $h8;
    }

    .date {
      font-weight: 400;
      font-size: $h7;
      display: flex;
      align-items: center;
      direction: ltr;

    }
  }

  .show-more-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: 400;
    font-size: $h7;
    color: $black;
    margin-top: 0.5rem;
  }

  &.info {
    .header-container {
      .value {
        color: $info;
      }
    }

    .date-container {
      background: rgba($info, 0.11);
      color: rgba($info, 0.7);
    }

    .show-more-container {
      color: $info;
    }
  }

  &.navy {
    .header-container {
      .value {
        color: $navy;
      }
    }

    .date-container {
      background: rgba($navy, 0.11);
      color: rgba($navy, 0.7);
    }

    .show-more-container {
      color: $navy;
    }
  }

  &.success {
    .header-container {
      .value {
        color: $success;
      }
    }

    .date-container {
      background: rgba($success, 0.11);
      color: rgba($success, 0.7);
    }

    .show-more-container {
      color: $success;
    }
  }

  &.primary {
    .header-container {
      .value {
        color: $primary;
      }
    }

    .date-container {
      background: rgba($primary, 0.11);
      color: rgba($primary, 0.7);
    }

    .show-more-container {
      color: $primary;
    }
  }

  &.warning {
    .header-container {
      .value {
        color: $warning;
      }
    }

    .date-container {
      background: rgba($warning, 0.11);
      color: rgba($warning, 0.7);
    }

    .show-more-container {
      color: $warning;
    }
  }

  &.danger {
    .header-container {
      .value {
        color: $danger;
      }
    }

    .date-container {
      background: rgba($danger, 0.11);
      color: rgba($danger, 0.7);
    }

    .show-more-container {
      color: $danger;
    }
  }
}