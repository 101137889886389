.auth-container {
  height: 100vh;
  background: linear-gradient(269.86deg,
      #dae6f9 13.26%,
      #dae6f9 41.11%,
      rgba(218, 230, 249, 0) 84.55%);
  overflow: auto;

  .auth-background-image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    background-size: contain;
    background-position: left 0px bottom 0px;
    background-repeat: no-repeat;
    display: table;
    background-blend-mode: hard-light;
    background-image: url("../../images/login.png");
    z-index: -1;
    opacity: 0.9;
  }

  .auth-card-container {
    padding: 1rem 2rem;
    background: #ffffff;
    box-shadow: 0px 20px 52px 7px rgba(0, 0, 0, 0.07);
    border-radius: 20px;

    .auth-header {
      text-align: center;

      .auth-logo {
        width: 90px;
        height: 90px;
        text-align: center;
        margin-bottom: 0.5rem;
      }

      .auth-title {
        font-weight: 700;
        font-size: $h5;
        line-height: 19px;
      }
    }
  }
}